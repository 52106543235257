import type { PropsWithChildren } from 'react';
import clsx from 'clsx';
import Container from 'components/01-atoms/Container';

type Props = {
  className?: string;
};

const HeroTextContainer = ({
  children,
  className
}: PropsWithChildren<Props>) => (
  <Container
    classes={clsx(className, 'grid relative z-10 narrow-x:mt-[-164px]')}
  >
    {children}
  </Container>
);

export default HeroTextContainer;
