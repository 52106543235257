import type { ReactNode } from 'react';

type Props = {
  slide: ReactNode;
};

const Slide = ({ slide }: Props) => (
  <div
    className="w-full max-w-full shrink-0 grow-0 relative block object-cover"
    role="tabpanel"
  >
    {slide}
  </div>
);

export default Slide;
