import { useTailwindBreakpoint } from 'helpers/hooks/useTailwindBreakpoint';

const HeroOverlay = () => {
  const { isScreenNarrowerThan } = useTailwindBreakpoint();
  const isMobile = isScreenNarrowerThan('narrow-x');
  return (
    <span
      className="h-full w-full absolute block left-0 bottom-0 top-0 pointer-events-none overlay-trailer z-10"
      style={{
        background: isMobile
          ? 'linear-gradient(180deg, rgba(0, 0, 0, 0) 37.03%, #000000 98.13%)'
          : 'linear-gradient(210.36deg, rgba(0, 0, 0, 0) 51.35%, #000000 83.47%), linear-gradient(180deg, rgba(0, 0, 0, 0) 57.94%, #000000 93.76%)'
      }}
    />
  );
};

export default HeroOverlay;
