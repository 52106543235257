import Heading from 'components/01-atoms/Heading';

type Props = {
  title?: string;
};

const HeroTitle = ({ title }: Props) => (
  <Heading
    classes="max-w-2xl text-network-white mb-6 narrow-x:text-left"
    level={1}
    responsiveVariant="large"
    text={title}
  />
);

export default HeroTitle;
