import type { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const HeroBgContainer = ({ children }: Props) => (
  <div className="pointer-events-auto hero-video-wrapper">
    <div className="relative hero-video">{children}</div>
  </div>
);

export default HeroBgContainer;
