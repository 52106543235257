import Icon from 'components/01-atoms/Icon';
import type { Type } from 'components/01-atoms/Icon';

type Direction = 'next' | 'prev';

type Props = {
  direction: Direction;
  onClick: VoidFunction;
};

const ARROW_PROPS: Record<Direction, { ariaLabel: string; iconType: Type }> = {
  next: {
    ariaLabel: 'Next button',
    iconType: 'chevronRight'
  },
  prev: {
    ariaLabel: 'Previous button',
    iconType: 'chevronLeft'
  }
};

const Arrow = ({ direction, onClick }: Props) => (
  <button
    aria-label={ARROW_PROPS[direction].ariaLabel}
    className="hidden py-5 px-1 outline-none cursor-pointer pointer-events-auto justify-center hover:opacity-50 narrow-x:flex"
    onClick={onClick}
    type="button"
  >
    <Icon
      className="text-white"
      type={ARROW_PROPS[direction].iconType as Type}
      width={32}
    />
  </button>
);

export default Arrow;
