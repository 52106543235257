import Container from 'components/01-atoms/Container';
import Icon from 'components/01-atoms/Icon';
import Link from 'next/link';

type Props = {
  link: string;
};

const GoBack = ({ link }: Props) => (
  <Container as="div" classes="relative z-20">
    <Link
      aria-label="Back button"
      className="absolute top-4 left-4 narrow-x:top-12 narrow-x:left-14"
      href={link}
      title="Back button"
    >
      <Icon type="arrowLeftCircle" width={40} />
    </Link>
  </Container>
);

export default GoBack;
