import Button from 'components/02-molecules/Button';

export type Props = {
  href: string;
  text: string;
};

const HeroEyebrow = ({ href, text }: Props) => (
  <div className="inline-flex">
    <Button as="link" href={href} text={text} variant="text" />
  </div>
);

export default HeroEyebrow;
