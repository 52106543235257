import clsx from 'clsx';
import HorizontalListWithDivider from 'components/01-atoms/HorizontalListWithDivider';
import { getArrayOfTags } from 'helpers/utils/media/getArrayOfTags';

type Props = {
  className?: string;
  crew?: string;
  tags?: string;
};

const Tags = ({ className, crew, tags }: Props) => (
  <div
    className={clsx(
      'mt-10 narrow:mt-0 pt-2 narrow:pt-0 narrow:pl-7 border-t-1 narrow:border-l-1 narrow:border-t-0 border-theme-label-color border-opacity-30 empty:border-none narrow:justify-self-end',
      className
    )}
  >
    {tags && (
      <div className="text-network-white font-plus-jakarta-sans max-w-3xl mt-6 narrow:mt-0 narrow:mb-10 narrow:last:mb-0">
        <div className="flex">
          <span className="text-theme-border-color-focus font-plus-jakarta-sans mr-4 text-14">
            Tags:
          </span>
          <HorizontalListWithDivider
            classes="text-14"
            data={getArrayOfTags(tags, 3)}
          />
        </div>
      </div>
    )}
    {crew && (
      <div className="text-network-white font-plus-jakarta-sans max-w-3xl mt-6 narrow:mt-0">
        <div className="flex">
          <span className="text-theme-border-color-focus font-plus-jakarta-sans mr-4 text-14">
            Crew:
          </span>
          <HorizontalListWithDivider
            classes="text-14"
            data={getArrayOfTags(crew, 3)}
          />
        </div>
      </div>
    )}
  </div>
);

export default Tags;
