import HorizontalListWithDivider from 'components/01-atoms/HorizontalListWithDivider';
import Select from 'components/01-atoms/Select';
import type { SeriesSelectItem } from 'components/04-templates/SeriesDetailPage';
import type { UseSelectStateChange } from 'downshift';
import type { ReactNode } from 'react';

export type SeasonChangeHandler = (
  selectState: UseSelectStateChange<SeriesSelectItem>
) => void;

type Props = {
  details?: ReactNode[];
  handleSeasonChange: SeasonChangeHandler;
  seasonSelectOptions: SeriesSelectItem[];
  selectedSeasonIndex: number;
};

const SeasonPicker = ({
  details,
  handleSeasonChange,
  seasonSelectOptions,
  selectedSeasonIndex
}: Props) => (
  <div className="flex flex-col narrow-x:items-center narrow-x:flex-row gap-5 narrow-x:gap-7 mb-8">
    <Select
      onChange={handleSeasonChange}
      options={seasonSelectOptions}
      selectedIndex={selectedSeasonIndex}
    />
    {details && <HorizontalListWithDivider data={details} />}
  </div>
);

export default SeasonPicker;
