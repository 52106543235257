import clsx from 'clsx';
import HeroOverlay from 'components/01-atoms/HeroOverlay';
import Image from 'next/image';

type ObjectPosition = 'center' | 'top';

type Props = {
  imageUrl?: string;
  objectPosition?: ObjectPosition;
  title?: string;
};

const HeroImage = ({ imageUrl, objectPosition = 'center', title }: Props) => (
  <>
    <Image
      alt={title}
      className={clsx('object-cover', {
        'object-center': objectPosition === 'center',
        'object-top': objectPosition === 'top'
      })}
      fill
      priority
      sizes="100vw"
      src={imageUrl}
    />
    <HeroOverlay />
  </>
);

export default HeroImage;
