import type { ReactNode } from 'react';

import BgContainer from './BgContainer';
import Video from './Video';
import Image from './Image';
import TextContainer from './TextContainer';
import Title from './Title';
import Eyebrow from './Eyebrow';
import FilmCTA from './FilmCTA';
import Description from './Description';
import EventBadge from './EventBadge';
import Slider from './Slider';
import Trailer from './Trailer';
import GoBack from './GoBack';
import SeasonPicker from './SeasonPicker';
import Tags from './Tags';

type Props = {
  children: ReactNode;
};

const Hero = ({ children }: Props) => (
  <div className="grid narrow:pb-16">{children}</div>
);

Hero.BgContainer = BgContainer;
Hero.Video = Video;
Hero.Trailer = Trailer;
Hero.Image = Image;
Hero.Slider = Slider;
Hero.TextContainer = TextContainer;
Hero.Title = Title;
Hero.Eyebrow = Eyebrow;
Hero.FilmCTA = FilmCTA;
Hero.Description = Description;
Hero.EventBadge = EventBadge;
Hero.GoBack = GoBack;
Hero.SeasonPicker = SeasonPicker;
Hero.Tags = Tags;

export default Hero;
