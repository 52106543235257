import PageLoader from 'components/01-atoms/PageLoader';
import type { MediaItem } from 'helpers/types/jwplayer-subgraph';
import { getPlayerScript } from 'helpers/utils/media/getPlayerScript';
import { TRAILER_PLAYER_ID } from 'helpers/constants/jwplayer';
import { getMediaPlaylist } from 'helpers/utils/media/getMediaPlaylist';
import Image from 'next/image';
import HeroOverlay from 'components/01-atoms/HeroOverlay';
import VideoPlayer from 'components/01-atoms/VideoPlayer';
import { getImageByWidth } from 'helpers/utils/getImageByWidth';

type Props = {
  mediaItem?: MediaItem;
};

const Video = ({ mediaItem }: Props) => {
  if (!mediaItem) {
    return (
      <div className="flex items-center justify-center h-full w-full">
        <PageLoader />
      </div>
    );
  }

  const filmId = mediaItem?.filmId || mediaItem?.mediaId;
  const trailerId = mediaItem.associatedTrailerId || mediaItem.mediaId;

  return (
    <>
      {filmId !== trailerId ? (
        <VideoPlayer
          cast={false}
          classes="z-1 preview-video atomic-video"
          controls
          hideCaptions
          isAutoPlay
          isMuted
          playerId={TRAILER_PLAYER_ID}
          playerScript={getPlayerScript(TRAILER_PLAYER_ID)}
          playlist={getMediaPlaylist(trailerId)}
          repeat
          stretching="fill"
          width="100%"
        />
      ) : (
        <Image
          alt={mediaItem.title}
          className="object-cover object-center"
          fill
          sizes="100vw"
          src={getImageByWidth(mediaItem.images, 1920).src}
        />
      )}
      <HeroOverlay />
    </>
  );
};

export default Video;
