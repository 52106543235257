import Button from 'components/02-molecules/Button';
import HorizontalListWithDivider from 'components/01-atoms/HorizontalListWithDivider';
import { useCallback } from 'react';
import type { ReactNode } from 'react';
import MediaItemProgress from 'components/02-molecules/MediaItemProgress';
import Flex from 'components/01-atoms/Flex';
import { useTailwindBreakpoint } from 'helpers/hooks/useTailwindBreakpoint';
import Icon from 'components/01-atoms/Icon';
import IconLoader from 'components/01-atoms/Loader/IconLoader';
import { getWatchCTAButtonText } from 'helpers/utils/media/getWatchCTAButtonText';
import { useMountVideo } from 'helpers/hooks/useMountVideo';
import type { MediaItem } from 'helpers/types/jwplayer-subgraph';

import WatchCTAButton from '../WatchCTAButton';

type Props = {
  callToAction?: VoidFunction;
  details?: ReactNode[];
  disabled?: boolean;
  isLoading?: boolean;
  mediaProgress?: MediaProgress;
  placement: Analytics['placement'];
  title?: string;
  trailerMediaItem?: MediaItem;
};

const HeroFilmCTA = ({
  callToAction,
  details,
  disabled,
  isLoading,
  mediaProgress,
  placement,
  title,
  trailerMediaItem
}: Props) => {
  const { isScreenNarrowerThan } = useTailwindBreakpoint();
  const isMobile = isScreenNarrowerThan('narrow');

  const renderCtaButton = () => {
    if (isLoading) {
      return (
        <Button
          as="button"
          className="min-w-[156px]"
          icon={<IconLoader />}
          size="lg"
          text={null}
          variant="contained"
        />
      );
    }
    return disabled ? (
      <Button
        as="button"
        disabled
        icon={<Icon className="text-btn-disabled-text" type="play" />}
        text="Watch"
        title={title}
        variant="contained"
      />
    ) : (
      <WatchCTAButton
        onClick={callToAction}
        placement={placement}
        text={getWatchCTAButtonText(mediaProgress)}
        title={title}
      />
    );
  };

  const { mountVideo } = useMountVideo();

  const handleTrailerClick = useCallback(() => {
    mountVideo(trailerMediaItem);
  }, [mountVideo, trailerMediaItem]);

  return (
    <>
      <div className="grid gap-y-6 items-center narrow:flex narrow:mt-2 mb-6">
        <div className="flex gap-6 items-center pointer-events-auto">
          {renderCtaButton()}
          {trailerMediaItem && (
            <Button
              analytics={{
                name: 'Watch trailer button',
                placement
              }}
              as="button"
              color="mono"
              icon={<Icon type="playTrailer" />}
              onClick={handleTrailerClick}
              text="Trailer"
              title={`${title} trailer`}
              variant="contained"
            />
          )}
        </div>
        {mediaProgress && isMobile ? (
          <Flex className="-mt-2">
            <MediaItemProgress
              classes="w-[133px]"
              label={mediaProgress.label}
              progress={!mediaProgress.isFinished ? mediaProgress.progress : 1}
            />
          </Flex>
        ) : null}
        {details && (
          <div className="ml-0 narrow:ml-9 pointer-events-auto">
            <HorizontalListWithDivider data={details} />
          </div>
        )}
      </div>
      {mediaProgress && !isMobile ? (
        <Flex className="-mt-2 mb-6">
          <MediaItemProgress
            classes="w-[155px]"
            label={mediaProgress.label}
            progress={!mediaProgress.isFinished ? mediaProgress.progress : 1}
          />
        </Flex>
      ) : null}
    </>
  );
};

export default HeroFilmCTA;
