import VideoPlayer from 'components/01-atoms/VideoPlayer';
import { getPlayerScript } from 'helpers/utils/media/getPlayerScript';
import { TRAILER_PLAYER_ID } from 'helpers/constants/jwplayer';
import { getMediaPlaylist } from 'helpers/utils/media/getMediaPlaylist';
import HeroOverlay from 'components/01-atoms/HeroOverlay';

type Props = {
  trailerId: string;
};

const Trailer = ({ trailerId }: Props) => (
  <>
    <VideoPlayer
      cast={false}
      classes="z-1 preview-video atomic-video"
      controls
      hideCaptions
      isAutoPlay
      isMuted
      playerId={TRAILER_PLAYER_ID}
      playerScript={getPlayerScript(TRAILER_PLAYER_ID)}
      playlist={getMediaPlaylist(trailerId)}
      repeat
      stretching="fill"
      width="100%"
    />
    <HeroOverlay />
  </>
);
export default Trailer;
