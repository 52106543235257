import clsx from 'clsx';

import Hero from '.';

type Props = {
  crew?: string;
  description?: string;
  isSeries?: boolean;
  tags?: string;
};

const HeroDescription = ({ crew, description, isSeries, tags }: Props) => (
  <div
    className={clsx('grid grid-cols-1 narrow:grid-cols-3 narrow:items-start', {
      'mb-8': isSeries
    })}
  >
    <div className="col-span-2">
      <p className="text-network-white font-plus-jakarta-sans max-w-2xl">
        {description}
      </p>
    </div>
    <Hero.Tags
      className={isSeries ? 'hidden narrow:block' : ''}
      crew={crew}
      tags={tags}
    />
  </div>
);

export default HeroDescription;
