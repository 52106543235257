import { useSelect } from 'downshift';
import type { UseSelectStateChange } from 'downshift';
import clsx from 'clsx';
import type { ReactNode } from 'react';
import { useId } from 'react';

import Icon from '../Icon';

export type OptionType = {
  label: string;
  value: ReactNode | string;
};

type Props<T> = {
  onChange: ({ selectedItem }: UseSelectStateChange<T>) => void;
  options: T[];
  selectedIndex: number;
};

const Select = <T extends OptionType>({
  onChange,
  options,
  selectedIndex
}: Props<T>) => {
  const uniqueId = useId();
  const selectedItem = options[selectedIndex] || options[0];

  const {
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    isOpen
  } = useSelect({
    id: uniqueId || 'select',
    items: options,
    onSelectedItemChange: onChange,
    selectedItem
  });

  const renderSelectItem = (item, index) => {
    const isHighlighted = highlightedIndex === index;
    const isSelected = selectedItem === item;

    return (
      <li
        key={`${item.value}`}
        className={clsx(
          'flex justify-between items-center cursor-pointer py-3.5 pl-4 pr-3 rounded font-light transition-colors duration-150 ease-in-out',
          {
            'bg-neutral-900': isHighlighted || isSelected
          }
        )}
        {...getItemProps({ index, item })}
      >
        <div
          className={clsx(
            'text-16 leading-6 font-plus-jakarta-sans font-light transition-colors duration-150 ease-in-out',
            {
              'text-dark-gray': !isHighlighted && !isSelected,
              'text-network-white': isHighlighted || isSelected
            }
          )}
        >
          {item.label}
        </div>
        {isSelected && <Icon className="text-white" type="check" width={24} />}
      </li>
    );
  };

  return (
    <div className="relative">
      <div
        aria-label={selectedItem.label}
        className="flex justify-between items-center cursor-pointer py-3.5 px-3 border border-border-faint rounded-lg bg-network-black min-w-40"
        {...getToggleButtonProps()}
      >
        <div className="font-plus-jakarta-sans text-network-white text-16 leading-6 font-light">
          {selectedItem.label}
        </div>
        <div
          className={clsx('transition duration-150 ease-linear', {
            'rotate-180': isOpen
          })}
        >
          <Icon className="text-white" type="chevronDown" width={24} />
        </div>
      </div>
      <ul
        {...getMenuProps()}
        className={clsx(
          'absolute top-[calc(100%+4px)] left-1/2 -translate-x-1/2 bg-red z-50 w-[calc(100%+8px)] bg-network-black max-h-[268px] overflow-auto rounded p-1 flex flex-col gap-1',
          {
            hidden: !isOpen
          }
        )}
      >
        {options.map(renderSelectItem)}
      </ul>
    </div>
  );
};

export default Select;
