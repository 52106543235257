import type { ReactNode } from 'react';

import HeroSlideshow from '../HeroSlideshow';

type Props = {
  slides: ReactNode;
};

const Slider = ({ slides }: Props) => <HeroSlideshow>{slides}</HeroSlideshow>;

export default Slider;
