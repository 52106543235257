import clsx from 'clsx';

type Props = {
  onClick: VoidFunction;
  selected: boolean;
};

const Dot = ({ onClick, selected }: Props) => (
  <button
    aria-label="Dot Navigation"
    className={clsx(
      'flex relative mx-1 h-2 w-2 after:rounded after:h-2 after:w-2',
      {
        'after:bg-network-outside-yellow': selected,
        'after:bg-neutral-600': !selected
      }
    )}
    onClick={onClick}
    type="button"
  />
);

export default Dot;
