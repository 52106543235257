import Container from 'components/01-atoms/Container';
import useEmblaCarousel from 'embla-carousel-react';
import type { PropsWithChildren } from 'react';
import { Children, useCallback, useEffect, useState } from 'react';

import Arrow from './Arrow';
import Dot from './Dot';
import Slide from './Slide';
import { useRecursiveTimeout } from './useRecursiveTimeout';

type Props = {
  shouldAutoplay?: boolean;
  shouldLoop?: boolean;
  showDots?: boolean;
  slideInterval?: number;
  slideshowHeight?: string;
};

const HeroSlideshow = ({
  children,
  shouldAutoplay = true,
  shouldLoop = true,
  showDots = true,
  slideInterval = 7000,
  slideshowHeight = '624px'
}: PropsWithChildren<Props>) => {
  const [viewportRef, embla] = useEmblaCarousel({
    loop: shouldLoop,
    skipSnaps: false
  });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<Array<number>>([]);

  // Get Slides into an array
  const slides = Children.toArray(children);

  // Autoplay
  const autoplay = useCallback(() => {
    if (!embla) return;
    if (embla.canScrollNext()) {
      embla.scrollNext();
    } else {
      embla.scrollTo(0);
    }
  }, [embla]);

  // useRecursiveTimeout
  const { play, stop } = useRecursiveTimeout(autoplay, slideInterval);

  const scrollTo = useCallback(
    (index) => embla && embla.scrollTo(index),
    [embla]
  );

  // Next Nav
  const scrollNext = useCallback(() => {
    if (!embla) return;
    embla.scrollNext();
    stop();
  }, [embla, stop]);

  // Prev Nav
  const scrollPrev = useCallback(() => {
    if (!embla) return;
    embla.scrollPrev();
    stop();
  }, [embla, stop]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
  }, [embla]);

  // Click slides stops auto sliding
  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on('select', onSelect);
    embla.on('pointerDown', stop);
  }, [embla, onSelect, stop]);

  // Play slideshow
  useEffect(() => {
    if (shouldAutoplay) {
      play();
    }
  }, [play, shouldAutoplay]);

  return (
    <>
      <div className="group relative w-screen narrow-x:w-full max-w-full">
        <div
          ref={viewportRef}
          className="overflow-hidden w-full hover:cursor-move"
        >
          <div
            className="flex select-none max-w-full"
            style={{ maxHeight: slideshowHeight }}
          >
            {/* eslint-disable react/no-array-index-key */}
            {slides &&
              slides.map((slide, index) => <Slide key={index} slide={slide} />)}
          </div>
          <Container classes="hidden justify-between absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 narrow-x:group-hover:flex pointer-events-none">
            <Arrow
              aria-label="Previous Button"
              direction="prev"
              onClick={scrollPrev}
            />
            <Arrow
              aria-label="Next Button"
              direction="next"
              onClick={scrollNext}
            />
          </Container>
        </div>
      </div>
      {showDots && (
        <Container classes="flex mt-10 mb-6 narrow:mt-7 justify-center">
          {scrollSnaps.map((_, index) => {
            const isSelected = index === selectedIndex;
            return (
              <Dot
                /* eslint-disable react/no-array-index-key */
                key={index}
                onClick={() => scrollTo(index)}
                selected={isSelected}
              />
            );
          })}
        </Container>
      )}
    </>
  );
};

export default HeroSlideshow;
