import clsx from 'clsx';
import EventBadgeManager from 'components/02-molecules/EventBadgeManager';
import type { EventState } from 'helpers/types/jwplayer-subgraph';

type Props = {
  classes?: string;
  state: EventState;
};

const EventBadge = ({ classes, state }: Props) => (
  <div className={clsx('justify-self-start -mt-9 mb-4', classes)}>
    <EventBadgeManager state={state} />
  </div>
);

export default EventBadge;
